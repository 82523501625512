import React from "react"
import {graphql, Link} from "gatsby"
import Button from "../components/Button/Button"
import { PostSingleStyles } from "../components/Post/PostStyles"
import RichText from "../components/RichText"
import Seo from "../components/SEO"
import Layout from "../components/Layout"
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import styled from "styled-components";

const ProductGallery = styled.section`
  width: 100%;

  > .container {
    display: flex;
    flex-wrap: wrap;
    gap: calc(var(--gap) / 2);

    @media (min-width: 1024px) {
      gap: var(--gap);
    }

    > * {
      @media (min-width: 768px) {
        width: calc(33.333% - 14px);
      }

      @media (min-width: 1024px) {
        width: calc(25% - 30px);
      }
    }
  }
`

const PostTemplate = (data ) => {
  const { headline, date_created, text, gallery } = data.data.directus.news[0]
  return (
    <>
        {
            JSON.stringify()
        }
            <Seo title={headline} />
        <Layout>
            <section>
                <PostSingleStyles>
                    {headline && <h1 className="blogsingle__title">{headline}</h1>}
                    {date_created && (
                        <p className="blogsingle__date">Posted on {date_created}</p>
                    )}
                    {text && (
                        <article className="blogsingle__content">
                            <div dangerouslySetInnerHTML={{__html: text}}/>


                        </article>
                    )}
                </PostSingleStyles>

            </section>
            {gallery && (
                <ProductGallery className="section">
                    <div className="container">
                        {gallery.map((item, index) => {
                            let galleyImage = getImage(item.directus_files_id.imageFile.childImageSharp)
                            return <GatsbyImage key={index} image={galleyImage} className={''}/>
                        })}
                    </div>
                </ProductGallery>

            )}
            <section>
                <PostSingleStyles>
                <div className="blogsingle__back">
                    <Button to="/news" text="Back to news" as={Link}/>
                </div>
                </PostSingleStyles>
            </section>

        </Layout>


    </>
  )
}


export const query = graphql`query ($slug: String!) {
    directus {
        news(filter: {slug: {_eq: $slug}}) {
            headline
            status
            text
            gallery {
                id
                directus_files_id {
                    imageFile {
                        childImageSharp {
                            id
                            gatsbyImageData
                        }
                    }
                    id
                }
            }
            image {
                id
                imageFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
            slug
        }
    }
}`



export default PostTemplate
